<template lang="pug">
  div#app
    router-view
    //div.div-spacer
    //div.h-100.w-100.mt-2


</template>
<script>

export default {}
</script>
<style lang="scss">

.div-spacer {
  height: 7.5rem;
}

#app{
  background-color: $ts-bg-color1;
}
</style>