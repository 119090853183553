import * as fb from '@/plugins/firebase'
import quizUtil from "@/plugins/quiz.util";
import QuizService from "@/service/quiz.service";
import QuestionService from "@/service/question.service";

//let fakeResults = require('@/data/quiz-result.json');
export const state = {
    questions: null,
    quiz: null,
    campaignId: null,
    campaign: {
        id: null,
        bucketName: "",
        shoppingCartUrl: ""
    },
    quizPicture: {
        file: null,
        url: null
    },
    quizResult: null,
    messages: []
}

export const mutations = {
    setQuestions(state, questions) {
        state.questions = questions
    },

    setMessages(state, messages) {
        state.messages = messages
    },

    updateQuestionAnswer(state, question) {
        state.questions.filter((q) => {
            if (q.id === question.id) {
                q.answer = question.answer
                //console.log('answered question', question.id, question.answer)
            }
        })
    },

    updateAnsweredQuestions(state, questions) {
        state.questions = questions
    },

    quizSaved(state, quizForm) {
        state.questions = quizForm.questions
        state.quizPicture.quizId = quizForm.quizId
    },

    setQuizConfiguration(state, quizConfiguration) {
        state.configurations.quiz = quizConfiguration
    },
    setQuizPicture(state, file) {
        state.quizPicture.file = file
    },
    setCampaignId(state, campaignId) {
        state.campaignId = campaignId
    },

    setCampaign(state, campaign) {
        state.campaign = campaign
    },
    setQuiz(state, quiz) {
        state.quiz = quiz
    },
    setQuizResult(state, quizResult) {
        state.quizResult = quizResult
    }
}

export const actions = {

    async loadQuiz({commit}, campaignId) {
        let questions = []

        commit('setCampaignId', campaignId)

        await QuizService.getQuiz(campaignId).then(async quiz => {

            let campaign = {
                id: campaignId,
                bucketName: quiz.bucketName,
                shoppingCartUrl: quiz.shoppingCartUrl
            }

            commit('setCampaign', campaign)

            if (quiz.questions) {

                let prepareQuestionData = async (question) => {
                    question.images = false

                    let multipleQuestion = quizUtil.isMultipleOptionsQuestion(question)

                    question.answer = multipleQuestion ? [] : null

                    if (question.answers) {
                        question.answers.sort((a, b) => a.position - b.position)

                        /*await Promise.all(question.answers.map(async answer => {
                            if (answer.hasImage) {
                                answer.url = await fb.util.downloadImageUrl(fb.COLLECTION_PATH.CAMPAIGNS, quiz.bucketName, 'answers', answer.id, 'jpg')

                                if (!question.images) {
                                    question.images = !!answer.hasImage
                                }
                            }
                            return answer
                        }))*/
                    }

                    return question
                }
                questions = await Promise.all(quiz.questions.map(prepareQuestionData))
            }

            commit('setQuiz', quiz)

        })

        commit('setQuestions', questions)
    },

    answerQuestion({commit}, question) {
        commit('updateQuestionAnswer', question)
    },

    answerQuestions({commit}, questions) {
        commit('updateAnsweredQuestions', questions)
    },

    async sendQuiz({commit, dispatch}, isCustomerDialogQuiz) {

        let bucketName = this.state.quiz.quiz.bucketName;

        let quiz = {
            campaignId: this.state.quiz.quiz.campaignId,
            userId: this.state.user.user.id,
            questions: []
        }

        quiz.questions = this.state.quiz.questions.map(question => {
                let answeredQuestion = {}
                if (quizUtil.isOneOptionQuestion(question)) {
                    answeredQuestion.selectedAnswers = [{id: question.answer.id}]
                } else if (quizUtil.isMultipleOptionsQuestion(question)) {
                    answeredQuestion.selectedAnswers = question.answer.map(answer => {
                        return {
                            id: answer.id
                        }
                    })
                } else {
                    answeredQuestion.answeredText = question.answer
                }

                return {
                    id: question.id,
                    answeredQuestion: answeredQuestion
                }
            }
        )

        let quizResult = await QuizService.answerQuiz(quiz)

        if (isCustomerDialogQuiz && quizResult.selectedProducts) {
            for (const product of quizResult.selectedProducts) {
                if (product.ingredients) {
                    await Promise.all(product.ingredients.map(async ingredient => {
                        if (ingredient.hasImage) {
                            ingredient.url = await fb.util.downloadImageUrl(fb.COLLECTION_PATH.CAMPAIGNS, bucketName, 'ingredients', ingredient.id, ingredient.imageFormat)
                        }
                        return ingredient
                    }))
                }
            }
        }

        commit('setQuizResult', quizResult)

        dispatch('uploadQuizPicture', quizResult.quizId)

        return quizResult
    },

    prepareUploadQuizPicture({commit}, file) {
        commit('setQuizPicture', file)
    },

    async uploadQuizPicture({state}, quizId) {

        if (state.quizPicture.file) {
            await fb.storage.ref(`${fb.COLLECTION_PATH.QUIZ}/${quizId}`).put(state.quizPicture.file);
        }
        return Promise.resolve();
    },

    async fetchQuiz({commit}, quizId) {
        let quizResult = await QuizService.getQuizResults(quizId)

        if (quizResult.selectedProducts) {
            for (const product of quizResult.selectedProducts) {
                if (product.ingredients) {
                    await Promise.all(product.ingredients.map(async ingredient => {
                        if (ingredient.hasImage) {
                            ingredient.url = await fb.util.downloadImageUrl(fb.COLLECTION_PATH.CAMPAIGNS, quizResult.bucketName, 'ingredients', ingredient.id, ingredient.imageFormat)
                        }
                        return ingredient
                    }))
                }
            }
        }

        for (const aq1 of quizResult.answeredQuestions.filter(aq => QuestionService.isGoalsQuestion(aq.question))) {
            if (aq1.selectedAnswers) {
                let goals = []
                goals = aq1.selectedAnswers.slice(0, 5)
                goals.sort((a, b) => a.position - b.position);

                quizResult.goals = await Promise.all(goals.map(async goal => {
                        let iconUrl = ''
                        try {
                            iconUrl = await fb.util.downloadImageUrl(fb.COLLECTION_PATH.QUIZ_APP, '', 'goals', goal.id, 'png')
                        } catch (error) {
                            console.log('ignore goal url')
                        }
                        return {
                            description: goal.userText || goal.answer,
                            iconUrl: iconUrl
                        }
                    }
                ))
            }
        }

        commit('setQuizResult', quizResult)

        return quizResult
    },

}

export default {
    state,
    actions,
    mutations,
};