import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import * as fb from "@/plugins/firebase";

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyBp-75BLJeUjMGGzh-JCQIIBX3zMr_7Yog",
    authDomain: "shampoo-app.firebaseapp.com",
    databaseURL: "https://shampoo-app.firebaseio.com",
    projectId: "shampoo-app",
    storageBucket: "shampoo-app.appspot.com",
    messagingSenderId: "614693493888",
    appId: "1:614693493888:web:6492634d780c7798569a65"
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const firestore = firebase.firestore

// collection names refs
const COLLECTION_PATH = Object.freeze({
    USERS: 'users',
    CAMPAIGNS: 'campaigns',
    QUESTIONS: 'questions',
    ANSWERS: 'answers',
    CONFIGURATIONS: 'configurations',
    QUIZ: 'quiz',
    ANSWERED_QUESTIONS: 'answeredQuestions',
    QUIZ_APP: 'quiz-app',
})

const COLLECTION_IDS = Object.freeze({
    QUIZ_CONFIGURATION: 'quiz'
})

// collection references
const usersCollection = db.collection(COLLECTION_PATH.USERS)
const campaignsCollection = db.collection(COLLECTION_PATH.CAMPAIGNS)
const quizCollection = db.collection(COLLECTION_PATH.QUIZ)
const configurationsCollection = db.collection(COLLECTION_PATH.CONFIGURATIONS)

const util = {
    downloadImageUrl: async (collection, bucketName, path, id, imgFormat) => {

        let completePath = ''
        if (path !== '') {
            completePath = `${collection}/${bucketName}/${path}/${id}.${imgFormat}`
        } else {
            completePath = `${collection}/${bucketName}/${id}.${imgFormat}`
        }
        return await fb.storage.ref(completePath).getDownloadURL().catch(function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case 'storage/object-not-found':
                    // File doesn't exist
                    break;
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    break;
                case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    break;
            }
            throw error.code
        });
    },
}

// export utils/refs
export {
    db,
    auth,
    storage,
    util,
    firestore,
    usersCollection,
    campaignsCollection,
    quizCollection,
    configurationsCollection,
    COLLECTION_PATH,
    COLLECTION_IDS,
}
