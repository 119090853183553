import VueI18n from 'vue-i18n'
import Vue from 'vue'
import {TRANSLATION} from '@/constant/app'
import en from '@/lang/en.json'
import lt from '@/lang/lt.json'

const numberFormats = {
    'en': {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',

        },
    },
    'lt': {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',

        },
    },
}

Vue.use(VueI18n)
export const i18n = new VueI18n({
    locale: TRANSLATION.DEFAULT_LANGUAGE, // set locale
    fallbackLocale: TRANSLATION.FALLBACK_LANGUAGE,
    messages: {
        lt,
        en,
    }, // set locale messages,
    numberFormats,
})
