import {ANSWER_TYPES, SHAMPOO_BRAND} from "@/constant/app";
import MD5 from "crypto-js/md5";

const quizUtil = {
    isOneOptionQuestion: (question) => {
        return question && question.answerType === ANSWER_TYPES.ONE
    },
    isMultipleOptionsQuestion: (question) => {
        return question && question.answerType === ANSWER_TYPES.MULTIPLE
    },
    isTextQuestion: (question) => {
        return question && question.answerType === ANSWER_TYPES.TEXT
    },
    isFirstQuestion: (question) => {
        return question && question.order === 1
    },
    generateMD5HashForMailChimpUser(email) {
        return MD5(email.toLowerCase()).toString()
    },
    delay: (ms) => new Promise(res => setTimeout(res, ms)),
    getBottleBrandByCampaignId: (campaignId) => {
        switch (campaignId) {
            case 7:
                return SHAMPOO_BRAND.PURELY
            default:
                return SHAMPOO_BRAND.TAVO_SAMPUNAS

        }
    }

}

export default quizUtil
