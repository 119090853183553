import myAxios from "@/plugins/axios";

export default {
    getQuiz(campaignId) {

        return myAxios.publicInstance
            .get(`/quiz/${campaignId}`).then(resp => {
                return resp
            })
            .catch(error => {
                throw new Error(error);
            });
    },

    answerQuiz(quiz) {
        return myAxios.publicInstance
            .post(`/quiz/answer`, quiz).then(resp => {
                return resp
            })
            .catch(error => {
                throw new Error(error);
            });
    },

    getQuizResults(quizId) {
        return myAxios.publicInstance
            .get(`/quiz/${quizId}/full-result`).then(resp => {
                return resp
            })
            .catch(error => {
                throw new Error(error);
            });
    },

};