import Vue from 'vue'

import {extend, localize, ValidationObserver, ValidationProvider} from "vee-validate";
//import en from "vee-validate/dist/locale/en.json";
import en from "@/lang/vee-validate-en.json"
import lt from "@/lang/vee-validate-lt.json"
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize("en", en);
localize("lt", lt);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
