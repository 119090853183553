import {ANSWER_TYPES, QUESTION_PARAMETER} from "@/constant/app";

export default {
    isOneOptionQuestion(currentQuestion) {
        return currentQuestion && currentQuestion.answerType === ANSWER_TYPES.ONE
    },
    isMultipleOptionsQuestion(currentQuestion) {
        return currentQuestion && currentQuestion.answerType === ANSWER_TYPES.MULTIPLE
    },
    isTextQuestion(currentQuestion) {
        return currentQuestion && currentQuestion.answerType === ANSWER_TYPES.TEXT
    },
    isUploadQuestion(currentQuestion) {
        return currentQuestion && currentQuestion.answerType === ANSWER_TYPES.UPLOAD
    },
    isNameQuestion(currentQuestion) {
        return currentQuestion && currentQuestion.parameterName === QUESTION_PARAMETER.NAME
    },
    isBottleNameQuestion(currentQuestion) {
        return currentQuestion && currentQuestion.parameterName === QUESTION_PARAMETER.BOTTLE_NAME
    },
    isRegisterQuestion(currentQuestion) {
        return currentQuestion && currentQuestion.answerType === QUESTION_PARAMETER.REGISTER
    },
    isGoalsQuestion(currentQuestion) {
        return currentQuestion && currentQuestion.parameterName === QUESTION_PARAMETER.GOALS
    },
    isRatingQuestion(currentQuestion) {
        return currentQuestion && currentQuestion.answerType === ANSWER_TYPES.RATING
    },
    isHairType1(currentQuestion) {
        return currentQuestion && currentQuestion.parameterName === QUESTION_PARAMETER.HAIR_TYPE_1
    },
    isHairType2(currentQuestion) {
        return currentQuestion && currentQuestion.parameterName === QUESTION_PARAMETER.HAIR_TYPE_2
    },
    isHairType3(currentQuestion) {
        return currentQuestion && currentQuestion.parameterName === QUESTION_PARAMETER.HAIR_TYPE_3
    },
}