export const ANSWER_TYPES = Object.freeze(
    {
        ONE: 'ONE',
        MULTIPLE: 'MULTIPLE',
        TEXT: 'TEXT',
        UPLOAD: 'UPLOAD',
        RATING: 'RATING'
    })

export const QUIZ_STATUS = Object.freeze({
    NEW: 'NEW',
    CONFIRMED: 'CONFIRMED',
    ORDERED: 'ORDERED',
    WAITING: 'WAITING',
    DELIVERED: 'DELIVERED',
    CANCELLED: 'CANCELLED'
})

export const TRANSLATION = Object.freeze({
    DEFAULT_LANGUAGE: 'lt',
    FALLBACK_LANGUAGE: 'lt',
    //SUPPORTED_LANGUAGES: ['en', 'lt']
    SUPPORTED_LANGUAGES: ['lt']
})

export const QUESTION_PARAMETER = Object.freeze(
    {
        NAME: "NAME",
        BOTTLE_NAME: "BOTTLE_NAME",
        REGISTER: "REGISTER",
        GOALS: "GOALS",
        HAIR_TYPE_1: "HAIR_TYPE_1",
        HAIR_TYPE_2: "HAIR_TYPE_2",
        HAIR_TYPE_3: "HAIR_TYPE_3"
    })

export const SHAMPOO_BRAND = Object.freeze(
    {
        TAVO_SAMPUNAS: 'TAVO_SAMPUNAS',
        PURELY: 'PURELY'
    })