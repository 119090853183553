import Vue from 'vue'
import VueRouter from 'vue-router'
import {Trans} from '@/plugins/translation'
import {ROUTER_NAME} from "./names";

const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue')
const DialogQuiz = () => import(/* webpackChunkName: "DialogQuiz" */ '@/views/DialogQuiz.vue')
const QuizResults = () => import(/* webpackChunkName: "QuizResults" */ '@/views/QuizResults.vue')

Vue.use(VueRouter)

const routes = [
    {
        path: '/:lang',
        component: {
            template: '<router-view></router-view>',
        },
        beforeEnter: Trans.routeMiddleware,
        children: [
            {
                path: '',
                name: ROUTER_NAME.HOME,
                component: Home
            },
            {
                path: 'dialog',
                name: ROUTER_NAME.DIALOG_QUIZ,
                component: DialogQuiz,
            },
            {
                path: 'gift',
                name: ROUTER_NAME.GIFT_QUIZ,
                component: DialogQuiz,
            },
            {
                path: 'express',
                name: ROUTER_NAME.EXPRESS_QUIZ,
                component: DialogQuiz,
            },
            {
                path: 'purely',
                name: ROUTER_NAME.PURELY_QUIZ,
                component: DialogQuiz,
            },
            {
                path: 'results/:quizId',
                name: ROUTER_NAME.QUIZ_RESULTS,
                component: QuizResults
            }
        ]
    },
    {
        path: '/results2/:quizId',
        name: ROUTER_NAME.QUIZ_RESULTS,
        component: QuizResults
    },
    {
        // Redirect user to supported lang version if no specific path is matched.
        path: '*',
        redirect(to) {
            return `/${Trans.defaultLanguage}`; // Ensure default language is properly set
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: '/quiz/',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0, behavior: 'smooth'}
    }
})

export default router
