import myAxios from "@/plugins/axios";

export default {
    update(user) {
        return myAxios.publicInstance
            .post(`/user/${user.id || ''}`, user)
            .catch(error => {
                throw new Error(error);
            });
    },

};