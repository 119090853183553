
import UserService from '@/service/user.service'

export const state = {
    user: null

}

export const mutations = {
    setUser(state, user) {
        state.user = user
    },
}

export const actions = {

    async registerNewUser({commit}, user) {

        let savedUser = await UserService.update(user)

        commit('setUser', savedUser)

        return savedUser

    },
}


export default {
    state,
    actions,
    mutations,
};