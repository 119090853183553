import Vue from 'vue'

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {
    faArrowLeft,
    faArrowRight,
    faCheck,
    faCheckCircle,
    faEnvelope,
    faInfoCircle,
    faMoneyBill,
    faPaperPlane,
    faPhone,
    faSearch,
    faSmile,
    faStar,
    faUserCircle,
    faHandPointRight
} from '@fortawesome/free-solid-svg-icons'
import {faFacebook, faInstagram, faPaypal, faWhatsapp} from '@fortawesome/free-brands-svg-icons'

library.add(faUserCircle, faPaypal, faCheckCircle, faPhone, faHandPointRight, faInstagram, faFacebook, faEnvelope, faWhatsapp, faMoneyBill, faSearch, faInfoCircle, faStar, faArrowRight, faArrowLeft, faPaperPlane, faCheck, faSmile)

Vue.component('font-awesome-icon', FontAwesomeIcon)