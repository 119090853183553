import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'

// plugins
import {i18n} from '@/plugins/i18n'
import {Trans} from '@/plugins/translation'
import '@/plugins/vee-validate'
import '@/plugins/bootstrap-vue'
import '@/plugins/font-awesome'
import '@/plugins/axios'
import '@/plugins/vue-phone-number-input'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueSanitize from "vue-sanitize";
import VueTypedJs from 'vue-typed-js'

Vue.use(VueTypedJs)
Vue.use(VueSanitize);

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.config.productionTip = false

/*
Sentry.init({
    Vue,
    dsn: "https://d684d90c56704a578f359439f290ce71@o1130733.ingest.sentry.io/6174846",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "tavosampunas.lt", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    logErrors: true,
});
*/

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')