import Vue from 'vue'
import Vuex from 'vuex'
import quiz from '@/store/quiz.store';
import user from '@/store/user.store';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {quiz, user}
})
